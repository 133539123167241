import React from 'react';
import { Switch, Route } from 'react-router-dom';

// import WelcomePage from '../pages/WelcomePage';
import CalendarPage from '../pages/CalendarPage';
import VideoPage from '../pages/VideoPage';

const AppNavigation = () => {
	const [data, setData] = React.useState();

	React.useEffect(() => {
		fetch('./data.json')
			.then((res) => res.json())
			.then((data) => {
				setData(data.artists);
			})

	}, []);

	if (!data) return null;

	return (
		<Switch>
			<Route 
				exact
				path='/'
				render={(props) => <CalendarPage {...props} artists={data} />}
			/>

			<Route path='/:slug' render={({ match }) => <VideoPage artist={data.find((artist) => artist.slug === match.params.slug)} />} />
		</Switch>
	);
};

export default AppNavigation;
