import moment from 'moment';
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Loader from '../components/Loader';
import Layout from '../components/Layout';

// import VideoPlayer from '../components/VideoPlayer';

const VideoPlayer = React.lazy(() => import('../components/VideoPlayer'));

export const VideoPage = ({ artist }) => {
	const [redirect, setRedirect] = React.useState(false);
	const [isPlaying, setIsPlaying] = React.useState(false);

	const currentURL = window.location.href;

	React.useEffect(() => {
		const pageDate = moment(artist.date, 'DD-MM-YYYY');
		const currentDate = moment(new Date(), 'DD-MM-YYYY').startOf('day');

		if (pageDate.isAfter(currentDate)) {
			setRedirect(true);
		}
	}, [artist]);

	if (redirect) return <Redirect to='/' />;

	return (
		<Layout showHashtag hideHeader={isPlaying}>
			<Helmet>
				<title>{artist.meta.title}</title>
				<meta name='description' content={artist.meta.description} />

				<meta property='og:url' content={currentURL} />
				<meta property='og:title' content={artist.meta.title} />
				<meta property='og:description' content={artist.meta.description} />
				<meta
					property='og:image'
					content={`https://schindler-weelevateart.de/videos/poster/${artist.meta.image}`}
				/>

				<meta name='twitter:card' content='summary' />
				<meta name='twitter:site' content='' />
				<meta property='twitter:title' content={artist.meta.title} />
				<meta
					property='twitter:image.src'
					content={`https://schindler-weelevateart.de/videos/poster/${artist.meta.image}`}
				/>
			</Helmet>

			<div className='video-page'>
				{artist && (
					<Suspense fallback={<Loader />}>
						<VideoPlayer artist={artist} videoIsPlaying={(state) => setIsPlaying(state)} />
					</Suspense>
				)}
			</div>
		</Layout>
	);
};

VideoPage.propTypes = {
	artist: PropTypes.object.isRequired,
};

export default VideoPage;
