import React from 'react';

const DonationsBanner = () => {
	return (
		<div className='donations'>
			<div className='donations__text'>
				<p>Spende auch du an die Corona-Künstlerhilfe!</p>
				<p>#WeElevateArt</p>
			</div>

			<a
				href='https://www.paypal.com/donate/?hosted_button_id=ECAXL9NZVFC34'
				target='_blank'
				rel='noopener noreferrer'
                className='donations__link'
            >
				Jetzt Geld spenden
			</a>
		</div>
	);
};

export default DonationsBanner;
