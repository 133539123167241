import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

// import '../AFRAME/Components';

const HotSpot = (props) => {
	const [disabled, setDisable] = React.useState();
	const history = useHistory();

	const { id, date, slug, numberImage, position, rotation } = props.data;

	React.useEffect(() => {
		const hotspotDate = moment(date, 'DD-MM-YYYY');
		const currentDate = moment(new Date(), 'DD-MM-YYYY').startOf('day');

		if (hotspotDate.isAfter(currentDate)) {
			setDisable(true);
		}
	}, [date]);

	const handleClickHotSpot = () => {
		if (disabled) {
			props.showAlert();
		} else {
			history.replace(`/${slug}`);
		}
	};

	return (
		<a-entity
			id={`hotspot-${id}`}
			class='hotspot'
			position={position.join(' ')}
			rotation={rotation.join(' ')}
			scale='1.2 1.2 1.2'
			onClick={() => handleClickHotSpot()}
			hover-hotspot={`target: #hotspot-bg-${id}`}>

			<a-entity
				id={`hotspot-bg-${id}`}
				class='hotspot-hover'
				geometry='primitive: plane;'
				material={
					!disabled
						? 'shader: flat; src: #rectangle; color: #dc0000; transparent: true;'
						: `shader: flat; src: #${numberImage}_inactive; color: #E5E5E5; opacity: 0.8; transparent: true;`
				}
				scale={!disabled ? '1.2 1.2 1.2' : '0.8 0.8 0.8'}
				animation__mouseenter={
					!disabled &&
					'property: material.color; from: #dc0000; to: #f83636; startEvents: hoverIn; dur: 300; easing: easeInSine'
				}
				animation__mouseleave={
					!disabled &&
					'property: material.color; from: #f83636; to: #dc0000; startEvents: hoverOut; dur: 300; easing: easeInSine'
				}
			/>

			{!disabled && <a-entity
				id={`hotspot-number-${id}`}
				geometry='primitive: plane;'
				material={`shader: flat; src: #${numberImage}; transparent: true`}
				position='0 0 0.01'
				scale='0.5 0.5 0.5'
			/>}
		</a-entity>
	);
};

HotSpot.propTypes = {
	data: PropTypes.object.isRequired,
};

export default HotSpot;
