import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Header from './Header';

const Layout = ({ children, showHashtag, pageName, hideHeader }) => {
	return (
		<>

			<Helmet>
				<title>Schindler | WE ELEVATE ART</title>
				<meta name="description" content="Schindler | WE ELEVATE ART" />

				<meta property="og:url"             content="https://schindler-weelevateart.de/" />
				<meta property="og:type"            content="website" />
				<meta property="og:title"           content="Schindler | WE ELEVATE ART" />
				<meta property="og:description"     content="Schindler | WE ELEVATE ART" />
				<meta property="og:image"           content="https://schindler-weelevateart.de/keyvisual_2.png" />
			
				<meta name="twitter:card"           content="summary" />
				<meta name="twitter:site"           content="@WeElevateArt" />
				<meta property="twitter:title"      content="Schindler | WE ELEVATE ART" />
				<meta property="twitter:image.src"  content="https://schindler-weelevateart.de/keyvisual_2.png" />
			</Helmet>

			{!hideHeader && <Header showHashtag={showHashtag} />}
			<main className={pageName}>{children}</main>
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	showHashtag: PropTypes.bool.isRequired,
	pageName: PropTypes.string,
	hideHeader: PropTypes.bool,
};

export default Layout;
