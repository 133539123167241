import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import Loader from './components/Loader';
import IE11Page from './pages/IE11Page';
import AppNavigation from './navigation/AppNavigation';

import { loadScript } from './loadScript';

const App = () => {
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		loadScript('https://aframe.io/releases/1.0.4/aframe.min.js')
			.then(() => setLoading(false))
	}, []);

	if (loading) return <Loader />;

	if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1)
		return <IE11Page />;

	return (
		<BrowserRouter>
			<AppNavigation />
		</BrowserRouter>
	);
};

export default App;
