import React from 'react';

const Loader = () => {
	return (
		<div className='loader'>
			<div className='loader__indicator'>
				<div className='loader__bubble'></div>
				<div className='loader__bubble'></div>
				<div className='loader__bubble'></div>
			</div>

			<p className='loader__text'>Loading...</p>
		</div>
	);
};

export default Loader;
