import React from 'react';

import Layout from '../components/Layout';

const IE11Page = () => {
    return (
        <Layout showHashtag={false} pageName='ie-page'>
            <div className="ie-banner">
                <p>Dieser Browser unterstützt die Website <a href="https://schindler-weelevateart.de/">schindler-weelevateart.de</a> nicht. Bitte nutze einen anderen Browser.</p>
            </div>
        </Layout>
    )
}

export default IE11Page
