/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { useLocation } from 'react-router-dom';

import HotSpot from '../components/HotSpot';
import Loader from '../components/Loader';
import SnowEffect from '../components/SnowEffect';
import DonationsBanner from '../components/DonationsBanner';
import Layout from '../components/Layout';
import ExplanatioTooltip from '../components/ExplanatioTooltip';

import { useWindowSize } from '../hooks/useCheckMobileScreen';

import CloseIcon from '../assets/icons/close.svg';

function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

const CalendarPage = (props) => {
	const [showMessage, setShowMessage] = React.useState(false);
	const [messageText, setMessageText] = React.useState();
	const scene = React.useRef(null);
	const location = useLocation();
	const isMobile = useWindowSize();

	console.log(location.state)

	const renderMessagetext = () => {
		const num = getRandomInt(1, 3);

		if (num === 1) setMessageText('Bald ist es soweit...');
		if (num === 2) setMessageText('Noch etwas Geduld...');
		if (num === 3) setMessageText('Vorfreude ist die schönste Freude...');
	};

	const toggleAlert = () => {
		renderMessagetext();
		setShowMessage(true);

		setTimeout(() => {
			setShowMessage(false);
		}, 3000);
	};

	return (
		<Layout showHashtag={false} pageName='calendar-page'>
			<Loader />

			<a-scene
				ref={scene}
				cursor='rayOrigin: mouse; fuse: false;'
				raycaster='objects: .hotspot-hover'
				vr-mode-ui='enabled: false'
				loading-screen='enabled: false'
				preloader='type: custom; target: .spinner'>

				<a-assets class='assets'>
					<img id='background' src={require('../assets/panorama.jpg')} alt='' />
					<img id='rectangle' src={require('../assets/rectangle_white.png')} alt='' />

					<img id='door_01_inactive' src={require('../assets/inactive/01.png')} alt='' />
					<img id='door_02_inactive' src={require('../assets/inactive/02.png')} alt='' />
					<img id='door_03_inactive' src={require('../assets/inactive/03.png')} alt='' />
					<img id='door_04_inactive' src={require('../assets/inactive/04.png')} alt='' />
					<img id='door_05_inactive' src={require('../assets/inactive/05.png')} alt='' />
					<img id='door_06_inactive' src={require('../assets/inactive/06.png')} alt='' />
					<img id='door_07_inactive' src={require('../assets/inactive/07.png')} alt='' />
					<img id='door_08_inactive' src={require('../assets/inactive/08.png')} alt='' />
					<img id='door_09_inactive' src={require('../assets/inactive/09.png')} alt='' />
					<img id='door_10_inactive' src={require('../assets/inactive/10.png')} alt='' />
					<img id='door_11_inactive' src={require('../assets/inactive/11.png')} alt='' />
					<img id='door_12_inactive' src={require('../assets/inactive/12.png')} alt='' />
					<img id='door_13_inactive' src={require('../assets/inactive/13.png')} alt='' />
					<img id='door_14_inactive' src={require('../assets/inactive/14.png')} alt='' />
					<img id='door_15_inactive' src={require('../assets/inactive/15.png')} alt='' />
					<img id='door_16_inactive' src={require('../assets/inactive/16.png')} alt='' />
					<img id='door_17_inactive' src={require('../assets/inactive/17.png')} alt='' />
					<img id='door_18_inactive' src={require('../assets/inactive/18.png')} alt='' />
					<img id='door_19_inactive' src={require('../assets/inactive/19.png')} alt='' />
					<img id='door_20_inactive' src={require('../assets/inactive/20.png')} alt='' />
					<img id='door_21_inactive' src={require('../assets/inactive/21.png')} alt='' />
					<img id='door_22_inactive' src={require('../assets/inactive/22.png')} alt='' />
					<img id='door_23_inactive' src={require('../assets/inactive/23.png')} alt='' />
					<img id='door_24_inactive' src={require('../assets/inactive/24.png')} alt='' />

					<img id='door_01' src={require('../assets/numbers/01.png')} alt='' />
					<img id='door_02' src={require('../assets/numbers/02.png')} alt='' />
					<img id='door_03' src={require('../assets/numbers/03.png')} alt='' />
					<img id='door_04' src={require('../assets/numbers/04.png')} alt='' />
					<img id='door_05' src={require('../assets/numbers/05.png')} alt='' />
					<img id='door_06' src={require('../assets/numbers/06.png')} alt='' />
					<img id='door_07' src={require('../assets/numbers/07.png')} alt='' />
					<img id='door_08' src={require('../assets/numbers/08.png')} alt='' />
					<img id='door_09' src={require('../assets/numbers/09.png')} alt='' />
					<img id='door_10' src={require('../assets/numbers/10.png')} alt='' />
					<img id='door_11' src={require('../assets/numbers/11.png')} alt='' />
					<img id='door_12' src={require('../assets/numbers/12.png')} alt='' />
					<img id='door_13' src={require('../assets/numbers/13.png')} alt='' />
					<img id='door_14' src={require('../assets/numbers/14.png')} alt='' />
					<img id='door_15' src={require('../assets/numbers/15.png')} alt='' />
					<img id='door_16' src={require('../assets/numbers/16.png')} alt='' />
					<img id='door_17' src={require('../assets/numbers/17.png')} alt='' />
					<img id='door_18' src={require('../assets/numbers/18.png')} alt='' />
					<img id='door_19' src={require('../assets/numbers/19.png')} alt='' />
					<img id='door_20' src={require('../assets/numbers/20.png')} alt='' />
					<img id='door_21' src={require('../assets/numbers/21.png')} alt='' />
					<img id='door_22' src={require('../assets/numbers/22.png')} alt='' />
					<img id='door_23' src={require('../assets/numbers/23.png')} alt='' />
					<img id='door_24' src={require('../assets/numbers/24.png')} alt='' />
				</a-assets>

				<a-entity id='rig' rotation='0 170 0'>
					<a-camera id='cam' look-controls='reverseMouseDrag: true; reverseTouchDrag: true' />
				</a-entity>

				<a-entity light='type: ambient; color: #fff; intensity: 1' />

				<a-sky id='pano' material='shader: flat; src: #background;' />

				{props.artists.map((artist) => (
					<HotSpot
						key={artist.id}
						data={artist}
						showAlert={() => toggleAlert()}
					/>
				))}
			</a-scene>

			<CSSTransition in={showMessage} timeout={200} classNames='alert' unmountOnExit>
				<div className='alert'>
					<button className='alert__close' onClick={() => setShowMessage(false)}>
						<img src={CloseIcon} alt='' />
					</button>
					<p className='alert__text'>{messageText}</p>
				</div>
			</CSSTransition>

			{!location.state && !isMobile && <ExplanatioTooltip />}

			<SnowEffect />
			<DonationsBanner />
		</Layout>
	);
};

CalendarPage.propTypes = {
	artists: PropTypes.array.isRequired,
};

export default CalendarPage;
