import React from 'react';
import Icon from '../assets/icons/360deg.svg';
import Transition from 'react-transition-group/Transition';

const duration = 300;

const defaultStyle = {
    transition: `all ${duration}ms ease-in-out`,
    transform: "translate(-50%, 0%)",
};

const transitionStyles = {
	entering: { transform: "translate(-50%, 0%)" },
    entered:  { transform: "translate(-50%, 0%)"},
    exiting:  { transform: "translate(-50%, -100%)" },
    exited:  { transform: "translate(-50%, -100%)" },
};

const ExplanatioTooltip = () => {
    const [visible, setVisible] = React.useState(true);

    React.useEffect(() => {
        setTimeout(() => {
            setVisible(false);
        }, 5000);
    }, [])

	return (
		<Transition in={visible} timeout={duration}>
			{(state) => (
				<div
					className='explanation-tooltip'
					style={{
						...defaultStyle,
						...transitionStyles[state],
					}}>
					<img src={Icon} alt=''></img>

					<div className='explanation-tooltip__text'>
						<h4>Wie es funktioniert</h4>
						<p>Ziehe und finde das nächste Türchen.</p>
					</div>
				</div>
			)}
		</Transition>
	);
};

export default ExplanatioTooltip;
